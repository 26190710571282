import React from 'react';
import { Helmet } from 'react-helmet-async';
import BannerImage from './BannerImage';
import SvgShikshaFlower from '../shiksha logo_flower_png.svg';

const PrivacyPolicy = ({ pageNodeData }) => {  // Fix destructuring here
  if (!pageNodeData) {
    return <div className="text-center text-red-500">Privacy Policy data not found.</div>;
  }

  const { title, body, bannerImage } = pageNodeData;
  // console.log("Privacy Policy Data:", pageNodeData);

  return (
    <div className="relative z-0">
      {bannerImage && (
        <BannerImage imageUrl={bannerImage.url} alt={bannerImage.alt} />
      )}
    <div className="container mx-auto p-4">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <h1 className="text-2xl sm:text-xl lg:text-3xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
        {title}
        <div className="relative flex items-center justify-center line-with-image">
          <img src={SvgShikshaFlower} alt="headingflower" className="h-7 sm:h-6 lg:h-9 bg-white p-1 sm:p-2" />
        </div>
      </h1>
      <div className="text-sm sm:text-base lg:text-[15px] text-justify mt-1" dangerouslySetInnerHTML={{ __html: body?.value || 'No content available' }} />
    </div>
    </div>
  );
};

export default PrivacyPolicy;
