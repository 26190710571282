import React from "react";

const SiteMap = ({ menuItems = [] }) => {
  return (
    <div className="p-5 w-full max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6 text-center">Sitemap</h2>

      {menuItems.length === 0 ? (
        <p className="text-center text-gray-600">No menu items available.</p>
      ) : (
        <div className="flex flex-col items-center space-y-4">
          {/* Root Menu (Home) */}
          <div className="bg-blue-600 text-white font-semibold p-3 rounded-lg shadow-md min-w-[200px] text-center">
            <a href="/" >Home</a>
          </div>

          {/* Root Children (All other menus under Home) */}
          <div className="flex flex-col items-center">
            <div className="w-1 h-4 bg-gray-400"></div> {/* Connector Line */}
            <ul className="border-l-2 border-gray-400 pl-4 space-y-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-6 w-full">
              {menuItems.map((menuItem, index) => (
                <li key={index} className="relative flex flex-col items-start">
                  {/* Connector Line */}
                  <div className="absolute -left-4 w-4 h-[1px] bg-gray-400"></div>
                  {/* Child Menu Box */}
                  {menuItem.children && menuItem.children.length > 0 ? (
                    <div className="bg-gray-200 text-gray-800 p-2 rounded-lg shadow text-center">
                      {menuItem.title}
                    </div>
                  ) : (
                    <a
                      href={menuItem.url || "#"}
                      className="bg-gray-200 text-gray-800 hover:bg-gray-300 p-2 rounded-lg shadow text-center block"
                    >
                      {menuItem.title}
                    </a>
                  )}

                  {/* Submenus */}
                  {menuItem.children && menuItem.children.length > 0 && (
                    <div className="ml-6 mt-2">
                      <div className="w-1 h-4 bg-gray-400"></div> {/* Connector */}
                      <ul className="border-l-2 border-gray-400 pl-4 space-y-2">
                        {menuItem.children.map((child, childIndex) => (
                          <li key={childIndex} className="relative flex flex-col items-start">
                            {/* Connector Line */}
                            <div className="absolute -left-4 w-4 h-[1px] bg-gray-400"></div>
                            {/* Sub Child Menu Box */}
                            {child.children && child.children.length > 0 ? (
                              <div className="bg-gray-100 text-gray-800 p-2 rounded-lg shadow text-center">
                                {child.title}
                              </div>
                            ) : (
                              <a
                                href={child.url}
                                className="bg-gray-100 text-gray-800 hover:bg-gray-200 p-2 rounded-lg shadow text-center block"
                              >
                                {child.title}
                              </a>
                            )}

                            {/* Sub Child Menus */}
                            {child.children && child.children.length > 0 && (
                              <div className="ml-6 mt-2">
                                <div className="w-1 h-4 bg-gray-400"></div> {/* Connector */}
                                <ul className="border-l-2 border-gray-400 pl-4 space-y-2">
                                  {child.children.map((subChild, subChildIndex) => (
                                    <li key={subChildIndex} className="relative flex items-start">
                                      {/* Connector Line */}
                                      <div className="absolute -left-4 w-4 h-[1px] bg-gray-400"></div>
                                      {/* Sub Sub Child Menu Box */}
                                      <a
                                        href={subChild.url}
                                        className="bg-gray-50 text-gray-800 hover:bg-gray-100 p-2 rounded-lg shadow min-w-[140px] text-center block"
                                      >
                                        {subChild.title}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default SiteMap;
