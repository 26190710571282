import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "../../index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";

const Testimonials = ({ testimonials = [] }) => {
  const [expandedCard, setExpandedCard] = useState(null);

  const toggleReadMore = (index) => {
    setExpandedCard(index);
  };

  const closePopup = () => {
    setExpandedCard(null);
  };

  // Disable background scrolling when modal is open
  useEffect(() => {
    if (expandedCard !== null) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = ""; // Re-enable scrolling
    }

    // Cleanup on component unmount
    return () => {
      document.body.style.overflow = "";
    };
  }, [expandedCard]);

  // Slider settings for showing three cards at a time
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container mx-auto px-4">
      <div className="w-full mt-10 px-4 mb-12">
        <h3 className="text-2xl sm:text-xl lg:text-3xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
          Testimonials
          <div className="relative flex items-center justify-center line-with-image">
            <img
              src={SvgShikshaFlower}
              alt="headingflower"
              className="h-7 sm:h-6 lg:h-9 p-1 sm:p-2"
            />
          </div>
        </h3>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => {
            const description = testimonial.testimonialsDescription || "";
            const maxLength = 150; // Set maximum characters before truncation
            const shouldTruncate = description.length > maxLength;
            const displayDescription = shouldTruncate
              ? description.slice(0, maxLength)
              : description;

            return (
              <div key={index} className="p-4">
                <div className="border rounded-lg shadow-md p-6 h-auto sm:h-auto lg:h-[250px]  flex flex-col justify-between bg-white hover:bg-yellow-300">
                  <div className="flex flex-col items-center text-center h-full">
                    <p className="text-gray-700 italic text-sm">
                      <strong className="font-extrabold text-sm">"</strong>
                      {displayDescription}
                      {shouldTruncate && (
                        <span>...</span> // Add ellipsis for truncated text
                      )}
                      <strong className="font-extrabold">"</strong>
                    </p>
                    {shouldTruncate && (
                      <span
                        onClick={() => toggleReadMore(index)}
                        className="text-navy-blue cursor-pointer ml-1"
                      >
                        Read More
                      </span>
                    )}
                    <div className="mt-4">
                      <h3 className="text-xl font-semibold">
                        {testimonial.nameOfTestimonials}
                      </h3>
                      <p className="text-gray-500">
                        {testimonial.postsNameAndPlace}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>

      {/* Popup Modal */}
      {expandedCard !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-[90%] sm:w-[70%] lg:w-[50%] shadow-lg relative">
            {/* Close Button */}
            <button
              onClick={closePopup}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 font-bold text-xl"
            >
              &times;
            </button>

            {/* Scrollable Content */}
            <div
              className="overflow-y-auto"
              style={{
                maxHeight: "80vh", // Limit the height for scrolling
              }}
            >
              <div className="text-center">
                <p className="text-gray-700 italic text-lg">
                  <strong className="font-extrabold text-xl">"</strong>
                  {testimonials[expandedCard].testimonialsDescription}
                  <strong className="font-extrabold">"</strong>
                </p>
                <div className="mt-4">
                  <h3 className="text-xl font-semibold">
                    {testimonials[expandedCard].nameOfTestimonials}
                  </h3>
                  <p className="text-gray-500">
                    {testimonials[expandedCard].postsNameAndPlace}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Testimonials;
