import React, { useEffect } from "react";
import Breadcrumb from "./Breadcrumb"; // Import the Breadcrumb component

const BannerImage = ({ imageUrl }) => {
  const bannerHeight = imageUrl ? "h-auto" : "h-[100px]"; // Adjust height as needed

  // Preload the banner image dynamically to improve LCP
  useEffect(() => {
    if (imageUrl) {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = imageUrl;
      link.fetchpriority = "high";
      document.head.appendChild(link);
    }
  }, [imageUrl]);

  return (
    <div className={`relative mb-4 pt-[60px] ${bannerHeight}`}>
      {imageUrl && (
        <img
          src={imageUrl}
          srcSet={`
            ${imageUrl}?w=480 480w,
            ${imageUrl}?w=768 768w,
            ${imageUrl}?w=1200 1200w
          `}
          sizes="(max-width: 480px) 167px, (max-width: 768px) 300px, (max-width: 1200px) 430px, 100vw"
          alt="Website banner"
          className="w-full object-cover lg:h-[450.16px] sm:h-[300.375px] xs:h-[167.198px] xxs:h-[167.198px]"
          loading="eager"
          decoding="async"
          fetchpriority="high"
        />
      )}
      <div className="absolute bottom-0 left-0 right-0 bg-[rgba(0,0,0,0.3)] p-2">
        <Breadcrumb /> {/* Render the Breadcrumb component */}
      </div>
    </div>
  );
};

export default BannerImage;
