import React from "react";
import { Link } from "react-router-dom";

const NewsletterList = ({ newsletterData, currentLanguage }) => {
    // Sorting newsletters: Newest first, Old ones at the end
    const sortedNewsletters = [...newsletterData].sort((a, b) => {
        if (a.typeOfNewsletter?.name === "Old" && b.typeOfNewsletter?.name !== "Old") {
            return 1; // Push "Old" newsletters to the end
        }
        if (a.typeOfNewsletter?.name !== "Old" && b.typeOfNewsletter?.name === "Old") {
            return -1; // Keep new newsletters first
        }
        return new Date(b.created?.time) - new Date(a.created?.time); // Sort by created time (latest first)
    });

    return (
        <section className="bg-gray-100 py-10">
            <div className="container mx-auto px-5">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                    {sortedNewsletters.map((newsletter) => (
                        <div key={newsletter.id} className="bg-white shadow-lg rounded-lg overflow-hidden">
                            {newsletter.typeOfNewsletter?.name === "Old" ? (
                                <a
                                    href={newsletter.oldNewsletterPdf?.mediaFile?.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={newsletter.newsletterCoverImage?.mediaImage6?.url}
                                        alt={newsletter.newsletterCoverImage?.mediaImage6?.alt}
                                        className="w-full h-56"
                                        loading="lazy"
                                        decoding="async"
                                    />
                                </a>
                            ) : (
                                <Link
                                    to={`/${currentLanguage === "hi" ? "hi/" : ""}newsletter/${newsletter.path
                                        .split("/")
                                        .pop()}`}
                                >
                                    <img
                                        src={newsletter.newsletterCoverImage?.mediaImage6?.url}
                                        alt={newsletter.newsletterCoverImage?.mediaImage6?.alt}
                                        className="w-full h-56 object-cover"
                                    />
                                </Link>
                            )}

                            <div className="p-5">
                                {newsletter.typeOfNewsletter?.name === "Old" ? (
                                    <a
                                        href={newsletter.oldNewsletterPdf?.mediaFile?.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <h5 className="text-sm font-bold text-gray-800">{newsletter.title}</h5>
                                    </a>
                                ) : (
                                    <Link
                                        to={`/${currentLanguage === "hi" ? "hi/" : ""}newsletter/${newsletter.path
                                            .split("/")
                                            .pop()}`}
                                    >
                                        <h5 className="text-sm font-bold text-gray-800">{newsletter.title}</h5>
                                    </Link>
                                )}
                                <p className="text-gray-600 text-[11px]">{newsletter.shortDescription}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default NewsletterList;
