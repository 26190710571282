import React, { useEffect, useState } from "react";
import yaml from "js-yaml";
import ReCaptchaDemo from "../ReCaptchaDemo";
import BASE_URL from "../../config";

const UmangMannualForm = ({ umangData }) => {
  const node = umangData[0] || {};
  const { title, umangMannual } = node;

  const [formValues, setFormValues] = useState({});
  const [csrfToken, setCsrfToken] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [uniqueGrades, setUniqueGrades] = useState([]);
  const [uniqueYears, setUniqueYears] = useState([]);
  const [filteredGrades, setFilteredGrades] = useState(uniqueGrades);
  const [captchaToken, setCaptchaToken] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  
  // Effect to disable scrolling when popup is open
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showModal]);
  
  const handleCaptchaVerify = (token) => {
    setCaptchaToken(token); // Save token when CAPTCHA is verified
  };
  useEffect(() => {
    if (umangMannual && umangMannual.length > 0) {
      const grades = [...new Set(umangMannual.map((post) => post.grade))];
      const years = [...new Set(umangMannual.map((post) => post.year))].sort(
        (a, b) => b - a
      ); // Sort years in decreasing order
      setUniqueGrades(grades);
      setUniqueYears(years);
    } else {
      setUniqueGrades([]);
      setUniqueYears([]);
    }
  }, [umangMannual]);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/session/token?_format=json`
        );
        if (!response.ok) throw new Error("Failed to fetch CSRF token");
        const token = await response.text();
        setCsrfToken(token);
      } catch (err) {
        console.error("CSRF Token Error:", err);
      }
    };
    fetchCsrfToken();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const resetForm = () => {
    // Clear form fields
    document.querySelector("form").reset(); // Optionally, use React refs/state to manage fields
  
    setFormValues({});
      setSelectedGrade("");
      setSelectedYear("");
      // setCaptchaToken("");
    // Reset ReCaptcha token
    setCaptchaToken("");
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    if (name === "year") {
      setSelectedYear(value);

      // Ensure that both `item.year` and `value` are compared as strings
      const filtered = umangMannual
        .filter((item) => item.year === value) // Remove Number() conversion
        .map((item) => item.grade);

      // Use Set to ensure distinct grades
      setFilteredGrades([...new Set(filtered)]);
    } else if (name === "grade") {
      setSelectedGrade(value);
    }
  };

  const handleSelectSubmit = (e) => {
    e.preventDefault();
    if (selectedGrade && selectedYear) {
      setShowModal(true);
    } else {
      alert("Please select both grade and year.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaToken) {
      alert("Please complete the CAPTCHA.");
      return;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!emailRegex.test(formValues.emailid)) {
    alert("Invalid email format. Please enter a valid email like example@domain.com.");
    return;
  }
    try {
      const requestData = {
        webform_id: "umang_mannual",
        grade: selectedGrade,
        year: selectedYear,
        "g-recaptcha-response": captchaToken,
        ...formValues,
      };
      setIsSubmitting(true);

      const response = await fetch(
        `${BASE_URL}/webform_rest/submit?_format=json`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Basic c2hpa3NoYV9hZG1pbjpTaGlrc2hhaGNsQDIwMjQ=",
            "X-CSRF-Token": csrfToken,
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `HTTP error! Status: ${response.status}, Response: ${errorText}`
        );
      }

      // const data = await response.json();
      const selectedDocument = umangMannual.find(
        (item) => item.grade.trim() === selectedGrade.trim() && item.year === selectedYear
      );
      
      if (selectedDocument?.uploadMannual?.url) {
        window.open(selectedDocument.uploadMannual.url, "_blank");
      } else {
        console.error("No matching document found for the selected grade and year.");
      }

      // Reset both forms
      setFormValues({});
      setSelectedGrade("");
      setSelectedYear("");
      setCaptchaToken("");
      setShowModal(false); // Close the modal after submission
    } catch (err) {
      console.error("Submission Error:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderField = (key, config) => {
    switch (config["#type"]) {
      case "textfield":
      case "email":
      case "tel":
        return (
          <div key={key} className="grid-cols-2">
            <label
              className={`block text-gray-700 text-sm font-bold mb-2 ${
                config["#required"]
                  ? "after:content-['*'] after:ml-0.5 after:text-red-500"
                  : ""
              }`}
            >
              {config["#title"]}
            </label>
            <input
              type={config["#type"]}
              name={key}
              placeholder={config["#placeholder"]}
              required={config["#required"]}
              pattern={config["#type"] === "tel" ? "[0-9]{10}" : null} // Validation for tel input (10 digits)
              onChange={handleInputChange}
              value={formValues[key] || ""}
              className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
            />
          </div>
        );

      case "webform_actions":
        return (
          <div key={key} className="mb-4">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {config["#submit__label"]}
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  const webformFields = `
    name:
      '#type': textfield
      '#title': 'Name'
      '#placeholder': 'Enter Your Name'
      '#required': true
      '#required_error': 'This field is required'
    nameoforganization:
      '#type': textfield
      '#title': 'Name of Organization'
      '#required': true
      '#placeholder': 'Enter Name of Organization'
    designation:
      '#type': textfield
      '#title': 'Designation'
      '#placeholder': 'Enter Your Designation'
    state:
      '#type': textfield
      '#title': 'State'
      '#placeholder': 'Enter Your State Name'
    emailid:
      '#type': email
      '#title': 'Email ID'
      '#required': true
      '#placeholder': 'Enter Your Email ID'
    mobile:
      '#type': tel
      '#title': 'Mobile'
      '#placeholder': 'Enter your Mobile Number'
  `;

  let elements = {};
  try {
    elements = yaml.load(webformFields);
  } catch (err) {
    console.error("YAML Parsing Error:", err);
  }

  return (
    <div className="mx-auto">
      {/* Grade and Year Selection Form */}
      <form
        onSubmit={handleSelectSubmit}
        className="w-full max-w-lg mx-auto  bg-white shadow-md rounded px-8 pb-8 mb-4"
      >
        <h1 className="text-2xl font-bold text-navy-blue text-center mb-5">
          {title}
        </h1>
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-1">
            <label
              htmlFor="year"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Year
              <span className="text-red-500 ml-1">*</span>
            </label>
            <select
              name="year"
              value={selectedYear}
              onChange={handleSelectChange}
              className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
              required
            >
              <option value="">Select Year</option>
              {uniqueYears.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <div className="col-span-1">
            <label
              htmlFor="grade"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Grade
              <span className="text-red-500 ml-1">*</span>
            </label>
            <select
              name="grade"
              value={selectedGrade}
              onChange={handleSelectChange}
              className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
              required
            >
              <option value="">Select Grade</option>
              {filteredGrades.map((grade, index) => (
                <option key={index} value={grade}>
                  {grade}
                </option>
              ))}
            </select>
          </div>
          <div className="col-span-1 flex items-end">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold mt-1 py-1 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={() => setIsOpen(true)}
            >
              Search
            </button>
          </div>
        </div>
      </form>

      {/* Modal for Second Form */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-600 flex justify-center items-center bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative top-20 mx-auto p-5 border w-[35rem] shadow-lg rounded-md bg-white">
            <h2 className="text-xl font-bold mb-4">
              Download Umang planner for Year: {selectedYear} & {selectedGrade}
            </h2>

            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-2 gap-4"
            >
              {Object.keys(elements).map((key) =>
                renderField(key, elements[key])
              )}

              {/* Buttons Section */}
              <ReCaptchaDemo onValidate={handleCaptchaVerify} />
                <br />
              <div className="col-span-2 flex">
                
                <button
                  type="submit"
                  className={`bg-blue-500 hover:bg-blue-700 text-white font-bold my-4  mx-4 py-2 px-4  rounded focus:outline-none focus:shadow-outline  ${
                    captchaToken && !isSubmitting
                      ? ""
                      : "opacity-50 cursor-not-allowed"
                  }`}
                  disabled={!captchaToken || isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-700 text-white font-bold my-4 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => {
                    setShowModal(false);
                    setIsOpen(false);
                    resetForm();
                  }}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UmangMannualForm;
