import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReachandImpactContent from './ContentView/ReachandImpactContent';
import WorkingModelContent from './ContentView/WorkingModelContent';
import VisionandMissionContent from './ContentView/VisionandMissionContent';
import ProgramandCampaignContent from './ContentView/ProgramandCampaignContent';
import NodeContent from './ContentView/NodeContent';
import LeadershipDetail from './ContentView/LeadershipDetail';
import NewsletterDetail from './ContentView/NewsletterDetail';
import EventsDetail from './ContentView/EventsDetail';
import StoriesDetail from './ContentView/StoriesDetail';
import CareerForm from './Form/CareerForm';
import VideosContent from './ContentView/VideosContent';
import AnnualReportContent from './ContentView/AnnualReportContent';
import PrivacyPolicy from './ContentView/PrivacyPolicy';
import HomePage from './ContentView/HomePage';
import NotFound from './NotFound';
import Forbidden from './Forbidden';
import ServerError from './ServerError';
import SiteMap from './SiteMap';
import Unsubscribe from './Unsubscribe';

const metaData = {
  "/": { 
    title: "Home - Shiksha Initiative", 
    description: "Welcome to our homepage! Explore our latest updates.",
    keywords: "home, website, updates",
    ogTitle: "Welcome to Our Website",
    ogDescription: "Stay updated with our latest news and content."
  },
  "/about": { 
    title: "About Us - Shiksha Initiative", 
    description: "Learn more about our mission and values.",
    keywords: "about us, company, mission",
    ogTitle: "About Our Company",
    ogDescription: "Discover our values and what drives us."
  },
  "/contact": { 
    title: "Contact Us - Shiksha Initiative", 
    description: "Get in touch with us for more information.",
    keywords: "contact, support, help",
    ogTitle: "Contact Us",
    ogDescription: "Reach out to us for any inquiries."
  },
};

const getNodeComponent = (node, baseUrl, isMobileMenuOpen, currentLanguage, leadershipData, eventsData, storiesData, umangData, newsletterData, homePageData, programAndCampaignData) => {
  switch (node.__typename) {
    case 'NodeReachAndImpact':
      return <ReachandImpactContent node={node} baseUrl={baseUrl} isMobileMenuOpen={isMobileMenuOpen} currentLanguage={currentLanguage} />;
    case 'NodeWorkingModel':
      return <WorkingModelContent node={node} baseUrl={baseUrl} currentLanguage={currentLanguage} />;
    case 'NodeVissionAndMission':
      return <VisionandMissionContent node={node} baseUrl={baseUrl} currentLanguage={currentLanguage} />;
    case 'NodeProgramAndCampaign':
      return <ProgramandCampaignContent node={node} baseUrl={baseUrl} umangData={umangData} currentLanguage={currentLanguage} />;
    case 'NodeVideo':
      return <VideosContent node={node} baseUrl={baseUrl} currentLanguage={currentLanguage} />;
    case 'NodeAnnualReport':
      return <AnnualReportContent node={node} baseUrl={baseUrl} currentLanguage={currentLanguage} />;
    case 'NodeWebform':
      return <CareerForm node={node} baseUrl={baseUrl} currentLanguage={currentLanguage} />;
    case 'NodeHomePage':
      return <HomePage baseUrl={baseUrl} homePageData={homePageData} currentLanguage={currentLanguage} eventsData={eventsData} programAndCampaignData={programAndCampaignData} />;
    default:
      return (
        <NodeContent
          node={node}
          baseUrl={baseUrl}
          currentLanguage={currentLanguage}
          leadershipData={leadershipData}
          newsletterData={newsletterData}
          eventsData={eventsData}
          storiesData={storiesData}
        />
      );
  }
};



const RoutesMapper = ({ matchedNodes, currentLanguage, isMobileMenuOpen, leadershipData, eventsData, storiesData, umangData, newsletterData, homePageData, programAndCampaignData, menuItems, pageNodeData }) => {
  const location = useLocation();
  const currentMeta = metaData[location.pathname] || {
    title: "Shiksha Initiative",
    description: "Welcome to our website!",
    keywords: "website, services, content",
    ogTitle: "Shiksha Initiative",
    ogDescription: "Explore our services and latest updates."
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{currentMeta.title}</title>
        <meta name="description" content={currentMeta.description} />
        <meta name="keywords" content={currentMeta.keywords} />
        <meta property="og:title" content={currentMeta.ogTitle} />
        <meta property="og:description" content={currentMeta.ogDescription} />
      </Helmet>

      <Routes>
        <Route path={currentLanguage === 'en' ? "/leadership/:leaderId" : "/hi/leadership/:leaderId"} element={<LeadershipDetail leadershipData={leadershipData} currentLanguage={currentLanguage} />} />
        <Route path={currentLanguage === 'en' ? "/events/:eventsId" : "/hi/events/:eventsId"} element={<EventsDetail eventsData={eventsData} currentLanguage={currentLanguage} />} />
        <Route path={currentLanguage === 'en' ? "/stories/:storiesId" : "/hi/stories/:storiesId"} element={<StoriesDetail storiesData={storiesData} currentLanguage={currentLanguage} />} />
        <Route path={currentLanguage === 'en' ? "/newsletter-3/:newsletterId" : "/hi/newsletter-3/:newsletterId"} element={<NewsletterDetail newsletterData={newsletterData} currentLanguage={currentLanguage} />} />
        <Route path={currentLanguage === 'en' ? "/" : "/hi"} element={<HomePage homePageData={homePageData} eventsData={eventsData} currentLanguage={currentLanguage} programAndCampaignData={programAndCampaignData} />} />

        {matchedNodes.map(({ menuItem, matchedNodes }, index) =>
          matchedNodes.map((node, nodeIndex) => {
            const path = menuItem.childrenUrls && menuItem.childrenUrls[nodeIndex]
              ? currentLanguage === 'en'
                ? menuItem.childrenUrls[nodeIndex]
                : `/hi${menuItem.childrenUrls[nodeIndex].replace(/^\/hi/, '')}`
              : currentLanguage === 'en'
                ? menuItem.url
                : `/hi${menuItem.url.replace(/^\/hi/, '')}`;

            return (
              node && (
                <Route
                  key={`${index}-${nodeIndex}`}
                  path={path}
                  element={
                    <>
                      <Helmet>
                        <title>{node.title}</title>
                        <meta name="description" content={node.description || "Default description for this page."} />
                        <meta name="keywords" content={node.keywords || "default, keywords"} />
                        <meta property="og:title" content={node.ogTitle || node.title} />
                        <meta property="og:description" content={node.ogDescription || "Default OpenGraph description."} />
                      </Helmet>
                      {getNodeComponent(node, node.path, isMobileMenuOpen, currentLanguage, leadershipData, eventsData, storiesData, umangData, newsletterData, homePageData, programAndCampaignData)}
                    </>
                  }
                />
              )
            );
          })
        )}
        <Route path="/403" element={<Forbidden />} />
        <Route path="/500" element={<ServerError />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy pageNodeData={Array.isArray(pageNodeData) ? pageNodeData.find(node => node?.path === "/privacy-policy") : null} />} />
        <Route path="/sitemap" element={<SiteMap menuItems={menuItems} />} />
        <Route path="/unsubscribe/:subscriberId" element={<Unsubscribe />} />
      </Routes>
    </HelmetProvider>
  );
};

export default RoutesMapper;
