import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import "../../index.css";

const ProgramandCampaignHome = ({ programAndCampaignData }) => {
  // Separate data into programs and campaigns
  const programs = programAndCampaignData.filter((item) =>
    item.path.startsWith("/program")
  );
  const campaigns = programAndCampaignData.filter((item) =>
    item.path.startsWith("/campaign")
  );

  // State for managing active tab
  const [activeTab, setActiveTab] = useState("programs");

  // Slick slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Default for larger screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768, // Adjust for mobile screens
        settings: {
          slidesToShow: 1, // Show one slide on mobile
        },
      },
    ],
  };

  return (
    <div className="container mx-auto px-4">
      <div className="program-and-campaign-section mt-10 ">
        {/* Tab Navigation */}
        <div className="flex justify-center space-x-4 mb-6">
          <button
            onClick={() => setActiveTab("programs")}
            className={`px-4 py-2 rounded ${
              activeTab === "programs"
                ? "bg-navy-blue text-white"
                : "bg-gray-200"
            }`}
          >
            Programs
          </button>
          <button
            onClick={() => setActiveTab("campaigns")}
            className={`px-4 py-2 rounded ${
              activeTab === "campaigns"
                ? "bg-navy-blue text-white"
                : "bg-gray-200"
            }`}
          >
            Campaigns
          </button>
        </div>

        {/* Display Content Based on Active Tab */}
        {activeTab === "programs" && (
          <>
            {programs.length > 0 ? (
              <Slider {...settings}>
                {programs.map((item) => (
                  <div key={item.id} className="flex justify-center">
                    <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow-sm">
                      <a href={item.path}>
                        <img
                          className="rounded-t-lg object-cover w-full h-48"
                          src={item.bannerImage.url}
                          alt={item.bannerImage.alt || item.title}
                        />
                      </a>
                      <div className="p-5">
                        <a href={item.path}>
                          <h5 className="mb-2 text-xl text-center font-bold tracking-tight text-gray-900">
                            {item.title}
                          </h5>
                        </a>
                        {/* <p className="mb-3 font-normal text-gray-700">
                          {item.description}
                        </p> */}
                        <div className="p-2 text-center">
                        <Link
                          to={item.path}
                          className="inline-flex items-center px-3 py-2 text-sm font-medium text-white  bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                        >
                          Read More
                          <svg
                            className="w-3.5 h-3.5 ml-2"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5h12m0 0L9 1m4 4L9 9"
                            />
                          </svg>
                        </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              <p className="text-center text-lg">No programs available</p>
            )}
          </>
        )}

        {activeTab === "campaigns" && (
          <>
            {campaigns.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 justify-items-center">
                {campaigns.map((item) => (
                  <div
                  key={item.id}
                  className="campaign-card max-w-sm bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-transform transform duration-300 hover:scale-105 flex flex-col "
                >
                  <div className="w-full h-48 overflow-hidden rounded-t-lg">
                    <img
                      className="object-cover w-full h-full"
                      src={item.bannerImage.url}
                      alt={item.bannerImage.alt || item.title}
                    />
                  </div>
                  <div className="p-2 flex flex-col items-center text-center"> {/* Centers content */}
                    <h5 className="mb-2 text-xl font-semibold tracking-tight text-gray-900">
                      {item.title}
                    </h5>
                    <Link
                      to={item.path}
                      className="mt-4 px-4 py-2 bg-navy-blue text-white rounded-lg font-medium hover:bg-blue-700 transition-colors duration-200"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
                
                
                ))}
              </div>
            ) : (
              <p className="text-center text-lg">No campaigns available</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProgramandCampaignHome;
