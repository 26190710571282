import React, { useState, useRef, useEffect } from "react";
import CountrySelectSubcriber from "./CountrySelectSubcriber";
import ReCaptchaDemo from "../ReCaptchaDemo";
import BASE_URL from "../../config";

const SubscribeForm = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [organization, setOrganization] = useState("");
  const [organizationType, setOrganizationType] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const [messageType, setMessageType] = useState(""); // 'success' or 'error'
  const recaptchaRef = useRef(null);
  const [error, setError] = useState(""); // State for error message

  const organizationOptions = [
    "Primary/Composite School",
    "Middle School",
    "Secondary School",
    "Senior School",
    "Govt Educational Institute",
    "Non-Govt Educational Institute",
    "Research Organization",
    "Foundation",
    "Government",
    "Media/Press",
    // "Multilateral/Intergovernmental",
    "NGO",
    "Private for Profit",
    "Non-University Research",
    "University",
  ];

  const handleTextChange = (setter) => (e) => {
    const value = e.target.value;
    if (/^[A-Za-z\s]*$/.test(value)) {
      setter(value);
      setError(""); // Clear error when input is valid
    } else {
      setError("Special characters are not allowed!"); // Show error message
    }
  };

  useEffect(() => {
    // Stop background scrolling when modal is open
    document.body.style.overflow = "hidden";
    return () => {
      // Allow scrolling when modal is closed
      document.body.style.overflow = "";
    };
  }, []);

  const getCsrfToken = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/session/token?_format=json`
      );
      if (!response.ok) throw new Error("Failed to retrieve CSRF token");
      return await response.text();
    } catch (error) {
      console.error("Error fetching CSRF token:", error);
      throw error;
    }
  };

  const handleCaptchaVerify = (token) => {
    setCaptchaToken(token); // Save token when CAPTCHA is verified
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage("");
    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setMessage("Error: Please enter a valid email address.");
      setLoading(false);
      return;
    }

    setMessageType("");
  
    try {
      const csrfToken = await getCsrfToken();
  
      // Check if the email is already subscribed
      const checkResponse = await fetch(
        `${BASE_URL}/jsonapi/simplenews_subscriber/simplenews_subscriber?filter[mail]=${email}`,
        {
          method: "GET",
          headers: {
            Accept: "application/vnd.api+json",
          },
        }
      );
  
      const checkData = await checkResponse.json();
      const existingSubscriber = checkData?.data?.[0];
  
      if (existingSubscriber) {
        const subscriberId = existingSubscriber.id;
        const currentStatus = existingSubscriber.attributes.status;
  
        if (!currentStatus) {
          // User exists but is unsubscribed, re-subscribe them
          const resubscribeData = {
            data: {
              type: "simplenews_subscriber--simplenews_subscriber",
              id: subscriberId,
              attributes: { status: true },
            },
          };
  
          await fetch(
           `${BASE_URL}/jsonapi/simplenews_subscriber/simplenews_subscriber/${subscriberId}`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/vnd.api+json",
                Accept: "application/vnd.api+json",
                "X-CSRF-Token": csrfToken,
              },
              body: JSON.stringify(resubscribeData),
            }
          );
  
          setMessage("Welcome back! Your newsletter subscription is reactivated—get ready for fresh updates!");
          setMessageType("success");
        } else {
          setMessage("You are already subscribed.");
          setMessageType("error");
        }
      } else {
        // New subscription request
        const requestData = {
          mail: [{ value: email }],
          field_first_name: [{ value: firstName }],
          field_last_name: [{ value: lastName }],
          langcode: [{ value: "en" }],
          status: [{ value: 1 }],
          subscriptions: [
            {
              target_id: "default",
              target_type: "simplenews_newsletter",
              target_uuid: "31c49d5f-69ea-4377-835e-f9f447b2c5e3",
            },
            {
              target_id: "press_releases",
              target_type: "simplenews_newsletter",
              target_uuid: "d95a9a8b-9bbf-460d-9f33-b987d714180d",
            },
          ],
          field_city: city ? [{ value: city }] : [],
          field_country: country ? [{ value: country }] : [],
          field_organization: organization ? [{ value: organization }] : [],
          field_organization_type: organizationType ? [{ value: organizationType }] : [],
          field_state_province_district: stateProvince ? [{ value: stateProvince }] : [],
        };
  
        const response = await fetch(
          `${BASE_URL}/entity/simplenews_subscriber?_format=json`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-CSRF-Token": csrfToken,
            },
            body: JSON.stringify(requestData),
          }
        );
  
        if (!response.ok) {
          const errorText = await response.text();
          if (errorText.includes("already exists")) {
            setMessage("You are already subscribed.");
            setMessageType("error");
          } else {
            throw new Error("Failed to subscribe. " + errorText);
          }
        } else {
          setMessage("Subscription successful!");
          setMessageType("success");
        }
      }
  
      // ✅ Reset form fields after successful submission
      setEmail("");
      setFirstName("");
      setLastName("");
      setCity("");
      setCountry("");
      setOrganization("");
      setOrganizationType("");
      setStateProvince("");
      setCaptchaToken("");
  
      // ✅ Reset reCAPTCHA after submission
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
  
      // ✅ Auto-close success/error message after 3 seconds
      setTimeout(() => {
        setMessage("");
        setMessageType("");
      }, 3000);
      
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      setMessageType("error");
  
      // Auto-close error message after 3 seconds
      // setTimeout(() => {
      //   setMessage("");
      //   setMessageType("");
      // }, 3000);
  
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className=" bg-white rounded-lg shadow dark:bg-gray-700  mx-auto  pt-1 sm:p-6 md:p-8 overflow-hidden">
      <div className="flex items-center justify-between  border-b dark:border-gray-600">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
          Shiksha Initiative Newsletter Subscription
        </h3>
        <button
          type="button"
          onClick={onClose}
          className="text-black hover:text-white bg-transparent hover:bg-red-600 rounded-lg text-lg w-10 h-10 flex justify-center items-center dark:hover:bg-gray-600"
        >
          <span aria-hidden="true" className="text-2xl">
            &times;
          </span>
        </button>
      </div>
      {/* <p className="text-grey-400">Subscribe to receive SHIKSHA INITIATIVE's newsletters and email updates on job opportunities.</p> */}

      <div className="max-h-[50vh] overflow-y-auto p-1">
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 sm:grid-cols-2 gap-4"
        >
          <input
            type="email"
            placeholder="Email *"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full p-1 border  rounded text-black sm:p-2"
          />
          
          <input
            type="text"
            placeholder="First Name *"
            value={firstName}
            onChange={handleTextChange(setFirstName)}
            required
            className="w-full p-1 border rounded text-black sm:p-2"
          />
          <input
            type="text"
            placeholder="Last Name *"
            value={lastName}
            onChange={handleTextChange(setLastName)}
            required
            className="w-full p-1 border rounded text-black sm:p-2"
          />
          <input
            type="text"
            placeholder="City"
            value={city}
            onChange={handleTextChange(setCity)}
            className="w-full p-1 border rounded text-black sm:p-2"
          />
          <input
            type="text"
            placeholder="Organization Name"
            value={organization}
            onChange={handleTextChange(setOrganization)}
            className="w-full p-1 border rounded text-black sm:p-2"
          />
          <select
            value={organizationType}
            onChange={(e) => setOrganizationType(e.target.value)}
            className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
          >
            <option value="">Select Organization Type</option>
            {organizationOptions.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
          <CountrySelectSubcriber
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="w-full p-1 border rounded text-black sm:p-2  sm:text-sm "
          />
          <input
            type="text"
            placeholder="State/Province/District"
            value={stateProvince}
            onChange={handleTextChange(setStateProvince)}
            className="w-full p-1 border rounded text-black sm:p-2"
          />
          <ReCaptchaDemo ref={recaptchaRef} onValidate={handleCaptchaVerify} />
          <br />
          <div className="sm:col-span-2">
            <button
              type="submit"
              className={`bg-blue-700 text-white py-1 px-4 rounded w-full sm:py-2 ${
                captchaToken && !loading ? "" : "opacity-50 cursor-not-allowed"
              }`}
              disabled={!captchaToken || loading}
            >
              {loading ? "Subscribing..." : "Subscribe"}
            </button>
          </div>
        </form>
      </div>
      {message && (
        <p
          className={`p-4 text-center font-semibold ${
            messageType === "success" ? "text-green-900" : "text-red-600"
          }`}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default SubscribeForm;
