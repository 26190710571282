import { gql } from "@apollo/client";

const AnnualReportQueryHi = gql`
query MyQuery {
  nodeAnnualReports(first: 10, langcode: "hi") {
    nodes {
      title
      path
      created {
        time
      }
      id
      __typename
      bannerImage {
        url
        alt
      }
      annualReportSection {
        ... on ParagraphAnnualReportPdf {
          id
          annualReportFile {
            ... on MediaDocument {
              id
              name
              mediaDocument {
                url
                size
                name
                mime
              }
            }
          }
          pdfCoverImage {
            ... on MediaAnnualReportCoverImage {
              id
              name
              mediaImage3 {
                alt
                url
                title
              }
            }
          }
          titleOfPdf
        }
      }
    }
  }
}
`;
export default AnnualReportQueryHi;