import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoader = () => {
  return (
    <div className="p-5">
      {/* Skeleton for Navbar */}
      <Skeleton height={50} className="mb-4" />

      {/* Skeleton for Banner */}
      <Skeleton height={200} className="mb-4" />

      {/* Skeleton for Cards */}
      {/* <div className="grid grid-cols-3 gap-4">
         <Skeleton height={150} />
        <Skeleton height={150} />
        <Skeleton height={150} /> 
      </div> */}
    </div>
  );
};

export default SkeletonLoader;
