import React, { useState } from "react";
import SubscribeForm from "../Form/SubscribeForm";
import ShikshaLogo from "../shiksha_logo_white.svg";
import { Link } from "react-router-dom";
import SiteMap from "../SiteMap";

const FooterPage = ({ footerMenuItems = [], menuItems = [] }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  // console.log("FooterPage menuItems:", menuItems);

  return (
    <>
      <footer className="bg-navy-blue text-white p-8 mt-5">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
          {/* Section 1: Intro and Logo */}
          <div className="flex flex-col items-center md:items-start">
            <p className="px-4 py-3 sm:px-0 mb-5">
              Shiv Nadar Foundation introduced SHIKSHA Initiative in 2012 to
              eradicate illiteracy from India by creating a model which is
              easily replicable, scalable and measurable. SHIKSHA Initiative
              works with the mission to create lifelong learners, by enhancing
              the learning process and utilizing a technology-enabled learning
              program.
            </p>
            <Link to="/">
              <img
                src={ShikshaLogo}
                alt="logo"
                className="h-12 w-auto mx-auto md:mr-2"
              />
            </Link>
          </div>

          {/* Section 2: Footer Menu */}
          <div className="flex flex-col items-center md:items-start">
            <h2 className="text-lg font-semibold mb-4">Quick Links</h2>
            <ul className="pl-0 md:pl-6">
              {footerMenuItems.map((item, index) => (
                <li key={index} className="mb-2">
                  <a href={item.url} className="hover:underline">
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Section 3: Newsletter & Social Links */}
          <div className="flex flex-col items-center md:items-start">
            <h2 className="text-lg font-semibold mb-2">Stay Informed</h2>
            <p className="text-lg pb-2">Receive our Newsletter</p>
            {/* <a href='https://shikshacms.shikshainitiative.org/simplenews'><button className="text-white text-lg bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-400 font-semibold rounded-lg px-4 py-2 text-center mb-5">Subscribe Us</button></a> */}
            <button
              onClick={openModal}
              className="text-white text-lg bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-400 font-semibold rounded-lg px-4 py-2 text-center mb-5"
            >
              Subscribe
            </button>
            <address className="text-center md:text-left">
              SHIKSHA Initiative, Shiv Nadar Foundation
              <br />
              A9, Sector 3, Noida, Uttar Pradesh-201301
            </address>

            <ul className="social flex justify-center md:justify-start space-x-4 mt-4 py-5">
              {/* Social icons */}
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=100081130134592"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    className="w-10 h-7 fill-white hover:fill-yellow-500"
                    viewBox="0 0 512 512"
                  >
                    <path d="M320,85.333h64c5.891,0,10.667-4.776,10.667-10.667v-64C394.667,4.776,389.891,0,384,0h-64c-64.772,0.071-117.263,52.561-117.333,117.333V192H128c-5.891,0-10.667,4.776-10.667,10.667v64c0,5.891,4.776,10.667,10.667,10.667h74.667v224c0,5.891,4.776,10.667,10.667,10.667h64c5.891,0,10.667-4.776,10.667-10.667v-224h74.667c4.589-0.003,8.662-2.942,10.112-7.296l21.333-64c1.862-5.589-1.16-11.629-6.749-13.491c-1.084-0.361-2.22-0.546-3.363-0.547h-96v-74.667C288,99.66,302.327,85.333,320,85.333z" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/ShikshaInitiat1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    className="w-10 h-7 fill-white hover:fill-yellow-500"
                    viewBox="0 0 512 512"
                  >
                    <path d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568C480.224,136.96,497.728,118.496,512,97.248z"></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com/shikshainitiative"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    className="w-10 h-7 fill-white hover:fill-yellow-500"
                    viewBox="0 0 512 512"
                  >
                    <path d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48h192c61.76,0,112,50.24,112,112V352z"></path>
                    <path d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"></path>
                    <circle cx="393.6" cy="118.4" r="17.056"></circle>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/shiksha-initiative/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    className="w-10 h-7 fill-white hover:fill-yellow-500"
                    viewBox="0 0 24 24"
                  >
                    <path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z"></path>
                    <path d="m.396 7.977h4.976v16.023h-4.976z"></path>
                    <path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z"></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCOcXKtHTzpznLwRbS26nsBg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    className="w-10 h-7 fill-white hover:fill-yellow-500"
                    viewBox="0 0 90.677 90.677"
                  >
                    <path
                      d="M82.287,45.907c-0.937-4.071-4.267-7.074-8.275-7.521c-9.489-1.06-19.098-1.065-28.66-1.06c-9.566-0.005-19.173,0-28.665,1.06c-4.006,0.448-7.334,3.451-8.27,7.521c-1.334,5.797-1.35,12.125-1.35,18.094c0,5.969,0,12.296,1.334,18.093c0.936,4.07,4.264,7.073,8.272,7.521c9.49,1.061,19.097,1.065,28.662,1.061c9.566,0.005,19.171,0,28.664-1.061c4.006-0.448,7.336-3.451,8.272-7.521c1.333-5.797,1.34-12.124,1.34-18.093C83.61,58.031,83.62,51.704,82.287,45.907z M28.9,50.4h-5.54v29.438h-5.146V50.4h-5.439v-4.822H28.9V50.4z M42.877,
                79.839h-4.629v-2.785c-1.839,2.108-3.585,3.136-5.286,3.136c-1.491,0-2.517-0.604-2.98-1.897c-0.252-0.772-0.408-1.994-0.408-3.796V54.311h4.625v18.795c0,1.084,0,1.647,0.042,1.799c0.111,0.718,0.462,1.082,1.082,1.082c0.928,0,1.898-0.715,2.924-2.166v-19.51h4.629L42.877,79.839L42.877,79.839z M60.45,72.177c0,2.361-0.159,4.062-0.468,5.144c-0.618,1.899-1.855,2.869-3.695,2.869c-1.646,0-3.234-0.914-4.781-2.824v2.474h-4.625V45.578h4.625v11.189c1.494-1.839,3.08-2.769,4.781-2.769c1.84,0,3.078,0.969,3.695,2.88c0.311,1.027,0.468,2.715,0.468,5.132V72.177z M77.907,67.918h-9.251v4.525c0,2.363,0.773,3.543,2.363,3.543c1.139,0,1.802-0.619,2.066-1.855c0.043-0.251,0.104-1.279,0.104-3.134h4.719v0.675c0,1.491-0.057,2.518-0.099,2.98c-0.155,1.024-0.519,1.953-1.08,2.771c-1.281,1.854-3.179,2.768-5.595,2.768c-2.42,0-4.262-0.871-5.599-2.614c-0.981-1.278-1.485-3.29-1.485-6.003v-8.941c0-2.729,0.447-4.725,1.43-6.015c1.336-1.747,3.177-2.617,5.54-2.617c2.321,0,4.161,0.87,5.457,2.617c0.969,1.29,1.432,3.286,1.432,6.015v5.285H77.907z"
                    ></path>
                    <path d="M70.978,58.163c-1.546,0-2.321,1.181-2.321,3.541v2.362h4.625v-2.362C73.281,59.344,72.508,58.163,70.978,58.163z"></path>
                    <path d="M53.812,58.163c-0.762,0-1.534,0.36-2.307,1.125v15.559c0.772,0.774,1.545,1.14,2.307,1.14c1.334,0,2.012-1.14,2.012-3.445V61.646C55.824,59.344,55.146,58.163,53.812,58.163z"></path>
                    <path d="M56.396,34.973c1.705,0,3.479-1.036,5.34-3.168v2.814h4.675V8.82h-4.675v19.718c-1.036,1.464-2.018,2.188-2.953,2.188c-0.626,0-0.994-0.37-1.096-1.095c-0.057-0.153-0.057-0.722-0.057-1.817V8.82h-4.66v20.4c0,1.822,0.156,3.055,0.414,3.836C53.854,34.363,54.891,34.973,56.396,34.973z"></path>
                    <path d="M23.851,20.598v14.021h5.184V20.598L35.271,0h-5.242l-3.537,13.595L22.812,0h-5.455c1.093,3.209,2.23,6.434,3.323,9.646C22.343,14.474,23.381,18.114,23.851,20.598z"></path>
                    <path d="M42.219,34.973c2.342,0,4.162-0.881,5.453-2.641c0.981-1.291,1.451-3.325,1.451-6.067v-9.034c0-2.758-0.469-4.774-1.451-6.077c-1.291-1.765-3.11-2.646-5.453-2.646c-2.33,0-4.149,0.881-5.443,2.646c-0.993,1.303-1.463,3.319-1.463,6.077v9.034c0,2.742,0.47,4.776,1.463,6.067C38.069,34.092,39.889,34.973,42.219,34.973zM39.988,16.294c0-2.387,0.724-3.577,2.231-3.577c1.507,0,2.229,1.189,2.229,3.577v10.852c0,2.387-0.722,3.581-2.229,3.581c-1.507,0-2.231-1.194-2.231-3.581V16.294z"></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center  items-center">
            {/* <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4 max-w-md w-full"> */}
              <SubscribeForm onClose={closeModal} />
            {/* </div> */}
          </div>
        )}
      </footer>
      <div className="container mx-auto bg-white py-4 px-4 sm:px-0 flex flex-col sm:flex-row sm:items-center justify-between">
  {/* Right-aligned Links (on desktop), moves above in mobile */}
  <div className="flex space-x-4 justify-center order-1 sm:order-2">
    <Link
      to="/privacy-policy"
      className="text-sm sm:text-base text-navy-blue hover:underline"
    >
      Privacy Policy
    </Link>
    <Link
      to="/sitemap"
      className="text-sm sm:text-base text-navy-blue hover:underline"
    >
      Sitemap
    </Link>
  </div>

  {/* Left-aligned Copyright Text (always stays left on desktop, moves below on mobile) */}
  <p className="text-sm font-bold  text-center text-navy-blue sm:text-base order-2 sm:order-1 mt-2 sm:mt-0">
    © {new Date().getFullYear()} Shiksha Initiative - Shiv Nadar Foundation. All Rights Reserved.
  </p>
</div>

    </>
  );
};

export default FooterPage;
