import React, { useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";
import Breadcrumb from "./Breadcrumb";
import { Helmet } from "react-helmet";
import "../../index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LeadershipDetails = ({ leadershipData, currentLanguage }) => {
  const { leaderId } = useParams();
  const leader = leadershipData.find(
    (e) => e.path.split("/").pop() === leaderId
  );

  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [location]);

  if (!leader) return <div>Leader not found</div>;

  const uniqueTitles = new Set();

  // Settings for react-slick slider
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="relative z-0">
      <div className="relative">
        {leader.commonBanner?.mediaImage2 && (
          <img
            src={leader.commonBanner.mediaImage2.url}
            className="w-full object-cover lg:mt-[48px] h-[173px]"
            alt={leader.commonBanner.mediaImage2.alt}
          />
        )}
        <div className="absolute bottom-0 left-0 right-0 bg-[rgba(0,0,0,0.3)] p-2 sm:p-4">
          <Breadcrumb />
        </div>
      </div>

      <div className="container mx-auto px-4 mt-6">
        <div className="flex flex-col sm:flex-row items-center mb-[35px]">
          <div className="right-border-around relative w-[250px] mr-[40px] pt-15 pr-15 pb-0 pl-0">
            {leader.imageAbout?.url && (
              <img
                src={leader.imageAbout.url}
                alt={leader.imageAbout.alt}
                className="relative w-full z-10"
              />
            )}
          </div>
          <Helmet>
            <title>{leader.title}</title> {/* Set dynamic title here */}
          </Helmet>
          <h1 className="text-[15px] font-bold">
            <strong className="block font-medium text-[22px] mb-[10px]">
              {leader.title}
            </strong>
            <span className="text-base font-medium mt-1 block text-gray-700 h-12 overflow-hidden">
                        {leader.post}
             </span>
          </h1>
        </div>
        <div
          className="mb-10 text-justify"
          dangerouslySetInnerHTML={{ __html: leader.body?.value || "" }}
        />
      </div>

      <div className="bg-gray-300 pb-8">
        <div className="container mx-auto px-4 pt-5">
          <h2 className="text-xl sm:text-2xl lg:text-3xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
            MORE LEADERS
            <div className="relative flex items-center justify-center line-with-image">
              <img
                src={SvgShikshaFlower}
                alt="headingflower"
                className="h-6 sm:h-6 lg:h-8 relative z-10 bg-gray-300 p-1 sm:p-2"
              />
            </div>
          </h2>
          <Slider {...sliderSettings} className="mt-[45px]">
            {leadershipData
              .filter(
                (leader) =>
                  leader.path.split("/").pop() !== leaderId &&
                  leader.group?.name !== "CTO" &&
                  !uniqueTitles.has(leader.title) &&
                  uniqueTitles.add(leader.title)
              )
              .map((leader) => (
                <div key={leader.id} className="item">
                  <Link
                    className="group block relative text-center overflow-hidden"
                    to={`/${
                      currentLanguage === "hi" ? "hi/" : ""
                    }leadership/${leader.path.split("/").pop()}`}
                  >
                    {/* Card Layout */}
                    <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      <div className="relative w-full h-[200px] overflow-hidden rounded-t-lg bg-gray-200">
                        <img
                          src={leader.imageAbout.url}
                          alt={leader.imageAbout.alt}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="p-5">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                          {leader.title}
                        </h5>
                        <p className="text-base font-medium text-gray-700 dark:text-gray-300 h-24 overflow-hidden">
                          {leader.post}
                        </p>
                        {/* <p className="text-sm font-normal text-gray-500 dark:text-gray-400 line-clamp-2">
                          {leader.shortDescription}
                        </p> */}
                        <a
                          href={`/${
                            currentLanguage === "hi" ? "hi/" : ""
                          }leadership/${leader.path.split("/").pop()}`}
                          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Read more
                          <svg
                            className="w-3.5 h-3.5 ml-2"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5h12m0 0L9 1m4 4L9 9"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default LeadershipDetails;
