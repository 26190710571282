import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import BASE_URL from "../config";
const Unsubscribe = () => {
  const { subscriberId } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState("loading");

  const fetchCsrfToken = async () => {
    try {
      const response = await fetch(`${BASE_URL}/session/token`);
      if (!response.ok) throw new Error("Failed to fetch CSRF token");
      return await response.text();
    } catch (error) {
      console.error("CSRF Token Error:", error);
      return null;
    }
  };

  const handleUnsubscribe = async () => {
    try {
      const csrfToken = await fetchCsrfToken();
      if (!csrfToken) {
        setStatus("error");
        return;
      }

      const response = await fetch(
        `${BASE_URL}/entity/simplenews_subscriber/${subscriberId}?_format=json`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
            Authorization: "Basic " + btoa("admin:your_password"), // Update with correct credentials
          },
          body: JSON.stringify({
            status: [{ value: 0 }], // Set status to 0 (inactive)
          }),
        }
      );
      
      if (response.ok) {
        setStatus("success");
        setTimeout(() => navigate("/"), 3000);
      } else {
        setStatus("error");
      }
    } catch (error) {
      console.error("Error unsubscribing:", error);
      setStatus("error");
    }
  };

  useEffect(() => {
    if (subscriberId) {
      handleUnsubscribe();
    }
  }, [subscriberId]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      {status === "loading" && <p>Processing your request...</p>}
      {status === "success" && <p>You have successfully unsubscribed. Redirecting...</p>}
      {status === "error" && <p>Unsubscription failed. Try again later.</p>}
    </div>
  );
};

export default Unsubscribe;