import React from 'react';

const CountryNames = {
    afghanistan: 'Afghanistan',
    albania: 'Albania',
    algeria: 'Algeria',
    // "american samoa": 'American Samoa',
    andorra: 'Andorra',
    angola: 'Angola',
    // anguilla: 'Anguilla',
    // antarctica: 'Antarctica',
    antigua_and_barbuda: 'Antigua & Barbuda',
    argentina: 'Argentina',
    armenia: 'Armenia',
    // aruba: 'Aruba',
    // "ascension island": 'Ascension Island',
    australia: 'Australia',
    austria: 'Austria',
    azerbaijan: 'Azerbaijan',
    bahamas: 'Bahamas',
    bahrain: 'Bahrain',
    bangladesh: 'Bangladesh',
    barbados: 'Barbados',
    belarus: 'Belarus',
    belgium: 'Belgium',
    belize: 'Belize',
    benin: 'Benin',
    // bermuda: 'Bermuda',
    bhutan: 'Bhutan',
    bolivia: 'Bolivia',
    bosnia_and_herzegovina: 'Bosnia & Herzegovina',
    botswana: 'Botswana',
    brazil: 'Brazil',
    "british indian ocean territory": 'British Indian Ocean Territory',
    brunei: 'Brunei',
    bulgaria: 'Bulgaria',
    "burkina faso": 'Burkina Faso',
    burundi: 'Burundi',
    cambodia: 'Cambodia',
    cameroon: 'Cameroon',
    canada: 'Canada',
    chile: 'Chile',
    china: 'China',
    colombia: 'Colombia',
    comoros: 'Comoros',
    "costa rica": 'Costa Rica',
    croatia: 'Croatia',
    cuba: 'Cuba',
    cyprus: 'Cyprus',
    czechia: 'Czechia',
    "côte d’ivoire": 'Côte d’Ivoire',
    denmark: 'Denmark',
    djibouti: 'Djibouti',
    dominica: 'Dominica',
    "dominican republic": 'Dominican Republic',
    ecuador: 'Ecuador',
    egypt: 'Egypt',
    "el salvador": 'El Salvador',
    "equatorial guinea": 'Equatorial Guinea',
    eritrea: 'Eritrea',
    estonia: 'Estonia',
    eswatini: 'Eswatini',
    ethiopia: 'Ethiopia',
    fiji: 'Fiji',
    finland: 'Finland',
    france: 'France',
    gabon: 'Gabon',
    gambia: 'Gambia',
    georgia: 'Georgia',
    germany: 'Germany',
    ghana: 'Ghana',
    greece: 'Greece',
    greenland: 'Greenland',
    guatemala: 'Guatemala',
    guinea: 'Guinea',
    guyana: 'Guyana',
    haiti: 'Haiti',
    honduras: 'Honduras',
    hungary: 'Hungary',
    iceland: 'Iceland',
    india: 'India',
    indonesia: 'Indonesia',
    iran: 'Iran',
    iraq: 'Iraq',
    ireland: 'Ireland',
    israel: 'Israel',
    italy: 'Italy',
    jamaica: 'Jamaica',
    japan: 'Japan',
    jordan: 'Jordan',
    kazakhstan: 'Kazakhstan',
    kenya: 'Kenya',
    kuwait: 'Kuwait',
    kyrgyzstan: 'Kyrgyzstan',
    laos: 'Laos',
    latvia: 'Latvia',
    lebanon: 'Lebanon',
    lesotho: 'Lesotho',
    liberia: 'Liberia',
    libya: 'Libya',
    liechtenstein: 'Liechtenstein',
    lithuania: 'Lithuania',
    luxembourg: 'Luxembourg',
    madagascar: 'Madagascar',
    malawi: 'Malawi',
    malaysia: 'Malaysia',
    maldives: 'Maldives',
    mali: 'Mali',
    malta: 'Malta',
    mauritania: 'Mauritania',
    mauritius: 'Mauritius',
    mexico: 'Mexico',
    moldova: 'Moldova',
    monaco: 'Monaco',
    mongolia: 'Mongolia',
    montenegro: 'Montenegro',
    morocco: 'Morocco',
    mozambique: 'Mozambique',
    myanmar: 'Myanmar (Burma)',
    namibia: 'Namibia',
    nepal: 'Nepal',
    netherlands: 'Netherlands',
    newZealand: 'New Zealand',
    nicaragua: 'Nicaragua',
    niger: 'Niger',
    nigeria: 'Nigeria',
    norway: 'Norway',
    oman: 'Oman',
    pakistan: 'Pakistan',
    palau: 'Palau',
    panama: 'Panama',
    paraguay: 'Paraguay',
    peru: 'Peru',
    philippines: 'Philippines',
    poland: 'Poland',
    portugal: 'Portugal',
    qatar: 'Qatar',
    romania: 'Romania',
    russia: 'Russia',
    rwanda: 'Rwanda',
    saudiArabia: 'Saudi Arabia',
    senegal: 'Senegal',
    serbia: 'Serbia',
    seychelles: 'Seychelles',
    singapore: 'Singapore',
    slovakia: 'Slovakia',
    slovenia: 'Slovenia',
    "south africa": 'South Africa',
    "south korea": 'South Korea',
    spain: 'Spain',
    "sri lanka": 'Sri Lanka',
    sudan: 'Sudan',
    suriname: 'Suriname',
    sweden: 'Sweden',
    switzerland: 'Switzerland',
    syria: 'Syria',
    taiwan: 'Taiwan',
    tajikistan: 'Tajikistan',
    tanzania: 'Tanzania',
    thailand: 'Thailand',
    tunisia: 'Tunisia',
    turkey: 'Turkey',
    turkmenistan: 'Turkmenistan',
    uganda: 'Uganda',
    ukraine: 'Ukraine',
    "united arab emirates": 'United Arab Emirates',
    "united kingdom": 'United Kingdom',
    "united states": 'United States',
    uruguay: 'Uruguay',
    uzbekistan: 'Uzbekistan',
    venezuela: 'Venezuela',
    vietnam: 'Vietnam',
    yemen: 'Yemen',
    zambia: 'Zambia',
    zimbabwe: 'Zimbabwe'
};
const CountrySelectSubcriber = ({ name, value, onChange, required }) => {
    return (
      <>
        {/* <label className="block text-gray-700 text-sm font-bold mb-2">Country
        <span className="text-red-500 ml-1">*</span>
        </label> */}
        <select
          name={name}
          required={required}
          onChange={onChange}
          value={value}    
          className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
        >
          <option value="">Select a Country</option>
          {Object.entries(CountryNames).map(([key, country]) => (
            <option key={key} value={key}>{country}</option>
          ))}
        </select>
      </>
    );
  };

export default CountrySelectSubcriber;
