import React from "react";
import BannerImage from "./BannerImage";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";

const VisionandMissionContent = ({
  node,
  baseUrl,
  imageData,
  currentLanguage,
}) => {
  const { title, bannerImage, visionAndMissionPoint } = node;
  return (
    <div className="relative z-0">
      <BannerImage imageUrl={`${bannerImage.url}`} />
      <div className="container mx-auto px-4">
        <h1 className="text-2xl sm:text-xl lg:text-3xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
          {title}
          <div className="relative flex items-center justify-center line-with-image">
            <img
              src={SvgShikshaFlower}
              alt="headingflower"
              className="h-7 sm:h-6 lg:h-9 p-1 sm:p-2"
            />
          </div>
        </h1>
        <ul className="box-border">
          {visionAndMissionPoint.map((point, index) => (
            <li key={index} className="flex flex-col sm:flex-row mb-6 sm:mb-8 lg:mb-10">
              <figure className="px-4 sm:px-6 md:px-6 lg:px-8 py-4 sm:py-5 md:py-5 lg:py-6 bg-white mr-0 sm:mr-4 md:mr-6 lg:mr-8 h-[120px] sm:h-auto shadow-md">
                <img
                  src={`${point.iconWithWorkingModelPo.url}`}
                  alt={point.iconWithWorkingModelPo.alt}
                  className="p-4 sm:p-5 lg:p-6 border border-solid border-[#0164ae] rounded-full max-w-none"
                />
              </figure>
              <div className="lg:w-[80%] sm:w-[65%]">
                <p className="text-sm sm:text-base lg:text-lg">
                  <strong className="font-bold mb-2 block text-lg sm:text-xl lg:text-2xl">
                    {point.pointsTitle}
                  </strong>
                  <span className="text-sm sm:text-base text-justify">{point.pointsDescription}</span>
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default VisionandMissionContent;
