import { gql } from '@apollo/client';

const NewsletterQueryEn = gql`
query MyQuery {
  nodeSimplenewsIssues(
    first: 100
    langcode: "en"
  ) {
    nodes {
      title
      __typename
      typeOfNewsletter {
        ... on TermNewsletterTypeOld {
          id
          name
        }
      }
      shortDescription
      oldNewsletterPdf {
        ... on MediaOldNewsletterPdf {
          id
          name
          mediaFile {
            url
            name
          }
        }
      }
      newsletterCoverImage {
        ... on MediaNewsletterCoverImage {
          id
          name
          mediaImage6 {
            url
            alt
          }
        }
      }
      bannerImage {
        title
        url
        alt
      }
      newsletterDescription {
        value
      }
      path
      created {
        time
      }
    }
  }
}
`;
export default NewsletterQueryEn;
