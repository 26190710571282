import React, { useState, useEffect, useRef } from "react";
import yaml from "js-yaml";
import BannerImage from "../ContentView/BannerImage";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import ReCaptchaDemo from "../ReCaptchaDemo";
import BASE_URL from "../../config";

const CareerForm = ({ node, baseUrl }) => {
  const { title, bannerImage, jobPost } = node || {};
  const [expanded, setExpanded] = useState(null);
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [csrfToken, setCsrfToken] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resumeFileId, setResumeFileId] = useState(null);
  const [appliedPostName, setAppliedPostName] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const navigate = useNavigate();

  const openModal = (postName) => {
    setAppliedPostName(postName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCaptchaVerify = (token) => {
    setCaptchaToken(token); // Save token when CAPTCHA is verified
  };

  const resetForm = () => {
    // Clear form fields
    document.querySelector("form").reset(); // Optionally, use React refs/state to manage fields
    setFormData({}); // Reset form values
    setResumeFileId(null); // Reset resume file ID
    // Reset ReCaptcha token
    setCaptchaToken(null);
  };

  const formConfig = `
    name:
      '#type': textfield
      '#title': Name
      '#placeholder': 'Enter Your Name'
      '#required': true
      '#required_error': 'This field is required'
    
    mobile:
      '#type': tel
      '#title': Mobile
      '#placeholder': 'Enter Your Mobile Number'
      '#required': true
      '#required_error': 'This field is required'

    email:
      '#type': email
      '#title': Email
      '#placeholder': 'Enter Your Email'
      '#required': true
      '#required_error': 'This field is required'

    applied_post_name:
      '#type': textarea
      '#title': 'Applied Post Name'
      '#hidden': true
      '#required': true
      '#required_error': 'This field is required'

    upload_your_resume:
      '#type': document_file
      '#title': Upload Your Resume
      '#uri_scheme': public
      '#file_extensions': 'pdf,doc,docx'
      '#required': true
      '#required_error': 'This field is required'
  `;

  const parsedFormConfig = yaml.load(formConfig);
  const [error, setError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const fileInputRef = useRef();

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    // Cleanup when the component unmounts or modal closes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/session/token?_format=json`
        );
        if (!response.ok) throw new Error("Failed to fetch CSRF token");
        const token = await response.text();
        setCsrfToken(token);
      } catch (err) {
        console.error("CSRF Token Error:", err);
      }
    };
    fetchCsrfToken();
  }, []);

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
  
    let error = "";
  
    // Validation based on input type
    if (type === "text" && !/^[A-Za-z\s]*$/.test(value)) {
      error = "Special characters are not allowed!";
    } else if (type === "tel" && !/^\d{0,10}$/.test(value)) {
      error = "Phone number must be 10 digits!";
    } else if (type === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      error = "Invalid email format!";
    }
  
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormError((prev) => ({ ...prev, [name]: error }));
  };
  

  
  

  // Function to handle file upload
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // File upload URL
    const uploadPath = `${BASE_URL}/file/upload/node/resume_upload/field_upload?_format=json`;

    try {
      // Creating the fetch options with appropriate headers and body
      const response = await fetch(uploadPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/octet-stream", // Correct Content-Type
          "Content-Disposition": `file; filename="${file.name}"`, // File name
          Authorization: "Basic c2hpc3NoYV9hZG1pbjpTaGlrc2hhaGNsQDIwMjQ=",
          "X-CSRF-Token": csrfToken,
        },
        body: file, // Directly pass the file blob as body
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`File upload failed: ${response.status} - ${errorText}`);
        throw new Error(
          `HTTP error! Status: ${response.status}, Response: ${errorText}`
        );
      }

      const result = await response.json();
      // console.log("File upload successful:", result);
      const fileId = result.fid[0].value; // Extract file ID
      setResumeFileId(fileId); // Set the file ID to state
    } catch (error) {
      console.error("File upload error:", error);
      alert(
        "File upload failed. Please check the upload URL and webform configuration."
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      alert("Please complete the CAPTCHA.");
      return;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(formData.email)) {
      alert("Invalid email format. Please enter a valid email like example@domain.com.");
      return;
    }
    // console.log("Form submitted:", formData);
    const requestData = {
      webform_id: "career",
      upload_your_resume: resumeFileId,
      applied_post_name: appliedPostName,
      "g-recaptcha-response": captchaToken,
      ...formData,
    };

    setIsSubmitting(true);
    // console.log("Submitting form:", requestData);

    try {
      const response = await fetch(
          `${BASE_URL}/webform_rest/submit?_format=json`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Basic c2hpc3NoYV9hZG1pbjpTaGlrc2hhaGNsQDIwMjQ=",
            "X-CSRF-Token": csrfToken,
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error Response:", errorText);
        throw new Error(
          `HTTP error! Status: ${response.status}, Response: ${errorText}`
        );
      }

      // const data = await response.json();
      // console.log("Submission Response:", data);

      setShowSuccessMessage(true); // Show success message
      alert("Form submitted successfully!");

      setFormData({}); // Reset form values
      setResumeFileId(null); // Reset resume file ID
      setCaptchaToken("");
      setIsModalOpen(false); // Close the modal after submission
      fileInputRef.current.value = ""; // Clear the file input field

      setTimeout(() => {
        setShowSuccessMessage(false); // Hide success message after some time
        navigate("/careers"); // Navigate to another route
      }, 3000);
    } catch (err) {
      console.error("Submission Error:", err);
      setError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderFormFields = () => {
    return Object.entries(parsedFormConfig).map(([key, field]) => {
      if (["textfield", "email", "tel"].includes(field["#type"])) {
        return (
          <div key={key} className="mb-2">
            <label htmlFor={key} className="block mb-2 text-sm font-medium text-gray-900">
              {field["#title"]}
              <span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type={field["#type"]}
              id={key}
              name={key}
              placeholder={field["#placeholder"]}
              required={field["#required"]}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1.5"
              value={formData[key] || ""}
              onChange={handleInputChange}
            />
            {formError[key] && <p className="text-red-500">{formError[key]}</p>}
          </div>
        );
      }
      

      if (field["#type"] === "document_file") {
        return (
          <div key={key} className="mb-2">
            <label
              htmlFor={key}
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              {field["#title"]}
              <span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="file"
              id={key}
              name={key}
              accept={field["#file_extensions"]}
              required={field["#required"]}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1.5"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
            {formError[key] && <p className="text-red-500">{formError[key]}</p>}
            {/* Add validation message here */}
            <p className="mt-2 text-sm text-gray-600">
              File Size should not exceed 5 MB. Accepted Formats: DOC, DOCX,
              PDF.
            </p>
          </div>
        );
      }

      // Render hidden textarea for applied post name
      if (key === "applied_post_name") {
        return (
          <textarea
            key={key}
            id={key}
            name={key}
            value={appliedPostName} // Use appliedPostName state
            onChange={handleInputChange}
            hidden={true} // Hidden field
          />
        );
      }

      return null;
    });
  };
  return (
    <section>
      <div className="relative z-0">
        {bannerImage && bannerImage.url && (
          <BannerImage imageUrl={bannerImage.url} alt={bannerImage.alt || ""} />
        )}

        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-xl sm:text-xl lg:text-2xl font-bold uppercase text-navy-blue text-center">
            {title}
          </h2>
          <div className="relative flex items-center justify-center line-with-image">
            <img
              src={SvgShikshaFlower}
              alt="headingflower"
              className="h-6 sm:h-6 lg:h-8 bg-white p-2"
            />
          </div>
          <section className="mt-6 sm:mt-8">
            {jobPost && jobPost.length > 0 ? (
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-xs sm:text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-2 sm:px-6 sm:py-3">
                        Post Name
                      </th>
                      <th scope="col" className="px-4 py-2 sm:px-6 sm:py-3">
                        Positions
                      </th>
                      <th scope="col" className="px-4 py-2 sm:px-6 sm:py-3">
                        Location
                      </th>
                      <th scope="col" className="px-4 py-2 sm:px-6 sm:py-3">
                        <span className="sr-only">Details</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobPost.map((post, index) => (
                      <React.Fragment key={index}>
                        <tr className="bg-white border-b hover:bg-gray-50">
                          <th
                            scope="row"
                            className="px-4 py-2 sm:px-6 sm:py-4 font-medium text-gray-900 whitespace-nowrap"
                          >
                            {post.postName}
                          </th>
                          <td className="px-4 py-2 sm:px-6 sm:py-4">
                            {post.positions}
                          </td>
                          <td className="px-4 py-2 sm:px-6 sm:py-4">
                            {post.location}
                          </td>
                          <td className="px-4 py-2 sm:px-6 sm:py-4 text-right">
                            <button
                              onClick={() => handleToggle(index)}
                              className="font-medium text-blue-600 hover:underline"
                            >
                              {expanded === index
                                ? "Hide Details"
                                : "View Details"}
                            </button>
                          </td>
                        </tr>

                        {expanded === index && (
                          <tr>
                            <td
                              colSpan="4"
                              className="px-4 py-2 sm:px-6 sm:py-4"
                            >
                              <div className="p-4 bg-gray-100 rounded-lg">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: post.description.value,
                                  }}
                                />
                                <button
                                  onClick={() => openModal(post.postName)}
                                  className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                                >
                                  Apply Now
                                </button>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No job posts available at the moment.</p>
            )}
          </section>

          {/* Modal for Applying */}
          {isModalOpen && (
            <Modal
              isOpen={isModalOpen}
              closeModal={() => {
                resetForm(); // Reset form state and ReCaptcha when modal closes
                closeModal();
              }}
            >
              <h3 className="text-base sm:text-lg font-medium mb-4 text-center sm:text-left">
                Apply Now
              </h3>
              <form
                onSubmit={handleSubmit}
                className="mt-6 grid gap-4 sm:gap-6 sm:grid-cols-2"
              >
                {renderFormFields()}

                {/* ReCaptcha Component */}
                <ReCaptchaDemo onValidate={handleCaptchaVerify} />
                <br />
                <div className="my-4">
                  <button
                    type="submit"
                    className={`p-2 mt-3 px-2 rounded-lg bg-blue-500 text-white ${
                      captchaToken && !isSubmitting
                        ? ""
                        : "opacity-50 cursor-not-allowed"
                    }`}
                    disabled={!captchaToken || isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
            </Modal>
          )}

          {/* Success Message */}
          {showSuccessMessage && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
              <div className="bg-white p-4 sm:p-6 rounded shadow-md text-center max-w-md sm:max-w-md">
                <h3 className="text-base sm:text-lg font-bold mb-2 sm:mb-4">
                  Submission Successful!
                </h3>
                
      
                <p className="text-sm sm:text-base">
                  Your record has been submitted. We will get back to you soon.
                </p>
              </div>
            </div>
          )}

          {error && (
            <p className="text-red-500 mt-4 text-sm sm:text-base">
              {error.message}
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default CareerForm;
