import React, { useState, useRef, useEffect } from "react";
import BannerImage from "./BannerImage";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";

const VideosContent = ({ node }) => {
  const { title, bannerImage, videoSection } = node;
  const [currentPlayingVideo, setCurrentPlayingVideo] = useState(null);
  const videoRefs = useRef([]);

  // Function to convert YouTube URL to embedded format
  const getEmbeddedYouTubeUrl = (url) => {
    const match = url.match(/(?:youtube\.com\/(?:[^\/]+\/[^\/]+\/|(?:v|embed|shorts)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]+)/);
    return match ? `https://www.youtube.com/embed/${match[1]}?enablejsapi=1` : null;
  };

  useEffect(() => {
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
    }

    window.onYouTubeIframeAPIReady = () => {
      videoSection.forEach((video, index) => {
        const videoId = getEmbeddedYouTubeUrl(video.videoLinkEmbed.mediaOembedVideo);

        if (videoId) {
          videoRefs.current[index] = new window.YT.Player(`video-${index}`, {
            height: "390",
            width: "640",
            videoId: videoId.split("/embed/")[1].split("?")[0], // Extract video ID
            events: {
              onStateChange: (event) => onPlayerStateChange(event, index),
            },
          });
        }
      });
    };

    return () => {
      window.onYouTubeIframeAPIReady = null;
    };
  }, [videoSection]);

  const onPlayerStateChange = (event, index) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      if (currentPlayingVideo !== null && currentPlayingVideo !== index) {
        videoRefs.current[currentPlayingVideo].pauseVideo();
      }
      setCurrentPlayingVideo(index);
    }
  };

  return (
    <div className="relative z-0">
      <BannerImage imageUrl={`${bannerImage.url}`} />
      <div className="container mx-auto px-[40px]">
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
          {title}
          <div className="relative flex items-center justify-center mb-4 sm:mb-6 lg:mb-8 line-with-image">
            <img
              src={SvgShikshaFlower}
              alt="headingflower"
              className="h-7 sm:h-10 lg:h-9 relative z-10 bg-white p-1 sm:p-2"
            />
          </div>
        </h1>

        {/* Video Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {videoSection?.map((video, index) => {
            const embeddedUrl = getEmbeddedYouTubeUrl(video.videoLinkEmbed.mediaOembedVideo);
            return (
              <div key={video.id} className="bg-white shadow-md rounded-lg overflow-hidden">
                {/* Embedded Video */}
                <div className="video-wrapper">
                  {embeddedUrl ? (
                    <iframe
                      id={`video-${index}`}
                      className="w-full h-56"
                      src={embeddedUrl}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  ) : (
                    <p className="text-red-500">Invalid Video URL</p>
                  )}
                </div>

                {/* Video Content */}
                <div className="p-4">
                  <h2 className="text-xl font-semibold mb-2">{video.titleOfVideo}</h2>
                  <p className="text-gray-600 mb-4">{video.secondDescription}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default VideosContent;
