import React from "react";
import BannerImage from "./BannerImage";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";

const AnnualReportContent = ({ node, currentLanguage }) => {
  const { title, bannerImage, annualReportSection } = node;

  // Sort annual report sections by created date (latest first)
  const sortedReports = [...annualReportSection].sort((a, b) => 
    new Date(b.created?.time) - new Date(a.created?.time)
  );

  return (
    <div className="relative z-0">
      <BannerImage imageUrl={`${bannerImage.url}`} />
      <div className="container mx-auto px-[40px]">
        <h1 className="text-2xl sm:text-xl lg:text-3xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
          {title}
          <div className="relative flex items-center justify-center line-with-image">
            <img
              src={SvgShikshaFlower}
              alt="headingflower"
              className="h-7 sm:h-6 lg:h-9 p-1 sm:p-2"
            />
          </div>
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-1">
          {sortedReports.map((section) => (
            <div key={section.id} className="mb-6 max-w-xs max-h-[500px] px-2">
              {/* PDF Cover Image */}
              <a
                href={section.annualReportFile?.mediaDocument?.url}
                download={section.annualReportFile?.mediaDocument?.name}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={section.pdfCoverImage?.mediaImage3?.url}
                  alt={section.pdfCoverImage?.mediaImage3?.alt}
                  className="w-full object-contain rounded-md cursor-pointer"
                />
                <h3 className="text-lg text-navy-blue text-center font-medium mt-4">
                  {section.titleOfPdf}
                </h3>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnnualReportContent;
